<template>
    <div class="details">
        <div class="details-top">
            <div class="details-top-tit">{{details.title}}</div>
            <div class="details-top-tips" v-if="details.newsWriter || details.newsSource">
                <div v-if="details.newsWriter">作者：{{details.newsWriter}}</div>
                <div v-if="details.newsSource">来源：{{details.newsSource}}</div>
            </div>
        </div>
        <div class="details-content" v-html="details.content"></div>
        <div v-if="type == 'tender' && details.tenderAttachment">
            <div class="tender-tit">附件下载({{enclosure.length}})</div>
            <div class='enclosure' v-for="item in enclosure" :key='item.id'>
                <div :style='{backgroundPosition: getPosition(item.name)}' class='enclosureIcon'></div>
                <a :href='item.url' rel="noreferrer" target='_blank'>{{item.name}}</a>
            </div>
        </div>
        <div class="details-bottom">
            <div class="details-bottom-left">
                <div class="details-bottom-left-div" v-if="details.nextNewsTitle" @click="$router.push({path: '/details', query:{id: details.nextNewsTitle.id}})">
                    <div>上一篇</div>
                    <div class="details-bottom-left-div-tit">{{details.nextNewsTitle.title}}</div>
                </div>
                <div class="details-bottom-left-div" v-if="details.lastNewsTitle" @click="$router.push({path: '/details', query:{id: details.lastNewsTitle.id}})">
                    <div>下一篇</div>
                    <div class="details-bottom-left-div-tit">{{details.lastNewsTitle.title}}</div>
                </div>
            </div>
            <div class="details-bottom-right">
                <div>分享到：</div>
                <div>
                    <a class="shareIcon sina_weibo" title="分享到 新浪微博" hidefocus="true" href="javascript:;" @click="share('微博')"></a>
                    <!-- <a class="shareIcon qq_zone" title="分享到 QQ空间" hidefocus="true" href="javascript:;" @click="share('QQ')"></a> -->
                    <a class="shareIcon douban" title="分享到 豆瓣网" hidefocus="true" href="javascript:;" @click="share('豆瓣')"></a>
                    <a class="shareIcon baidu_tieba " title="分享到 百度贴吧" hidefocus="true" href="javascript:;" @click="share('百度贴吧')"></a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { newsView, tenderView } from '@/api'
export default {
    data(){
        return{
            id: '',
            type: 'new',
            details: {
                content: '',
                lastNewsTitle: null,
                newsSource: null,
                newsWriter: null,
                nextNewsTitle: null,
                title: ''
            },
            enclosure: [],
            enclosureIconPosition: [
                {
                    name: 'doc/docx',
                    position: '-11px -16px'
                },
                {
                    name: 'ppt/pptx',
                    position: '-11px -45px'
                },
                {
                    name: 'xls/xlsx',
                    position: '-11px -74px'
                },
                {
                    name: 'pdf',
                    position: '-12px -103px'
                },
                {
                    name: 'jpg',
                    position: '-12px -132px'
                },
                {
                    name: 'txt',
                    position: '-12px -161px'
                },
                {
                    name: 'png',
                    position: '-12px -190px'
                },
                {
                    name: '',
                    position: '-12px -219px'
                },
                {
                    name: 'rar',
                    position: '-12px -248px'
                },
                {
                    name: 'avi',
                    position: '-12px -277px'
                },
                {
                    name: 'zip',
                    position: '-12px -306px'
                },
                {
                    name: 'rm',
                    position: '-12px -335px'
                },
            ]
        }
    },
    created(){
        if(this.$route.query.id){
            this.id = this.$route.query.id
        }
        if(this.$route.query.type){
            this.type = this.$route.query.type
        }
        if(this.type == 'new'){
            this.getMedia()
        }
        if(this.type == 'tender'){
            this.getTender()
        }
    },
    watch:{
        $route(to,from){
            if(this.$route.query.id){
                this.id = this.$route.query.id
            }
            if(this.$route.query.type){
                this.type = this.$route.query.type
            }
            if(this.type == 'new'){
                this.getMedia()
            }
            if(this.type == 'tender'){
                this.getTender()
            }
        }
    },
    methods:{
        getMedia(){
           newsView(this.id).then(res => {
                console.log(res)
                this.details = res
           }) 
        },
        getTender(){
           tenderView(this.id).then(res => {
                console.log(res)
                this.details = {
                    newsWriter: res.tenderWriter,
                    title: res.tenderTitle,
                    content: res.tenderContent,
                    tenderAttachment: res.tenderAttachment,
                    nextNewsTitle: res.nextTenderTitle ? {
                        id: res.nextTenderTitle.id,
                        title: res.nextTenderTitle.tenderTitle
                    } : null,
                    lastNewsTitle: res.lastTenderTitle ? {
                        id: res.lastTenderTitle.id,
                        title: res.lastTenderTitle.tenderTitle
                    } : null
                }
                this.enclosure = res.tenderAttachment ? JSON.parse(res.tenderAttachment) : []
           }) 
        },
        getPosition(name) {
            var position = '-12px -219px'
            const lastName =  name.substr(name.lastIndexOf('.') + 1)
            this.enclosureIconPosition.forEach(item => {
                if(item.name.indexOf(lastName.toLowerCase()) >= 0){
                    position = item.position
                }
            })
            return position
        },
        share(type){
            var title = this.details.newsWriter ? encodeURI('【'+this.details.title+'+-+'+this.details.newsWriter+'】') : encodeURI('【'+this.details.title+'】')
            console.log(title)
            var href = encodeURI(window.location.href)
            if(type == '微博'){
                window.open("http://service.weibo.com/share/share.php?title="+title+"&url="+href+"")
            }
            if(type == 'QQ'){
                window.open("http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?summary="+href+"&url="+href+"&pics=http:&title="+title)
            }
            if(type == '豆瓣'){
                window.open("http://shuo.douban.com/!service/share?name="+title+href+"&text="+href+"&image=http:")
            }
            if(type == '百度贴吧'){
                window.open("http://tieba.baidu.com/i/app/open_share_api?comment=&pic=http:&url="+href+"&urlRdKey=nd&title="+title+href+"&urlRdKey=nd")
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.details{
    width: 1200px;
    margin: auto;
}
.details-top{
    margin: 20px 0;
}
.details-top-tit{
    font-size: 25px;
    color: #333;
    font-weight: 400;
    margin-bottom: 20px;
}
.details-top-tips{
    margin: 20px 0;
    font-size: 14px;
    color: #adadad;
    display: flex;
    div{
        margin-right: 20px;
    }
}
.details-content{
    border-top: 1px solid #f0efef;
    border-bottom: 1px solid #f0efef;
    padding: 30px 0;
}
.details-bottom{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 14px;
    color: #666;
    margin: 30px 0;
}
.details-bottom-left > div:nth-of-type(even){
    margin-top: 20px;
}
.details-bottom-right{
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.details-bottom-left-div{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.details-bottom-left-div-tit{
    margin-left: 30px;
}
.tender-tit{
    font-size: 14px;
    color: #333;
    margin: 40px 0 30px
}
.enclosure{
  display: flex;
  font-size: 12px;
  margin-bottom: 10px;
  cursor: pointer;
  a{
    word-break: break-all;
    text-align: left;
    color: #666666;
    text-decoration: none;
  }
}
.enclosure:last-child{
  margin-bottom: 0;
}
.enclosureIcon{
  background: url('https://dfosspic01.oss-cn-shenzhen.aliyuncs.com/images/dfinfowebsite/df/attach-smallIcon.png') no-repeat;
  width: 21px;
  height: 19px;
  margin-right: 8px;
}
.shareIcon{
    width: 33px;
    height: 33px;
    margin: 0 0 0 12px;
    display: inline-block;
}
.sina_weibo{
    background: url('https://dfosspic01.oss-cn-shenzhen.aliyuncs.com/images/dfinfowebsite/df/vbg01.png') -1302px -190px no-repeat;
}
.qq_zone {
    background: url('https://dfosspic01.oss-cn-shenzhen.aliyuncs.com/images/dfinfowebsite/df/vbg01.png') -1302px -235px no-repeat;
}
.douban {
    background: url('https://dfosspic01.oss-cn-shenzhen.aliyuncs.com/images/dfinfowebsite/df/vbg01.png') -1302px -730px no-repeat;
}
.baidu_tieba {
    background: url('https://dfosspic01.oss-cn-shenzhen.aliyuncs.com/images/dfinfowebsite/df/vbg01.png') -1302px -595px no-repeat;
}
</style>